<template>
  <v-card class="mx-auto" style="height: 400px" color="grey lighten-4">
    <l-map id="map" :zoom="zoom" :center="latLng" style="height: 100%">
      <l-tile-layer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        :attribution="$root.mapTileAttribution"
      >
      </l-tile-layer>
      <l-marker :lat-lng="latLng" :icon="truckIcon"> </l-marker>
      <l-marker
        :lat-lng="[ev.location.lat, ev.location.lon]"
        :icon="dotIcon"
        v-for="ev in events"
        :key="ev.timestamp"
      >
      </l-marker>
    </l-map>
  </v-card>
</template>

<script>
import L from "leaflet";
export default {
  props: {
    events: {
      type: Array,
      required: true,
    },
    location: {
      type: Object,
      required: true,
      // @TODO Validate lat and lng keys
    },
  },
  data: () => ({
    truckIcon: L.icon({
      iconUrl: require("@/assets/images/truck.png"),
    }),
    dotIcon: L.icon({
      iconUrl: require("@/assets/dot.png"),
    }),
    latLng: [42.1164, -101.2996],
    zoom: 15,
  }),
  watch: {
    location(val) {
      this.latLng = [val.lat, val.lng];
      this.zoom = 15;
    },
  },
};
</script>
